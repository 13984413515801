<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '950px' }"
    header="REGISTRAR NUEVA ORDEN DE COMPRA"
    :modal="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-5">
          <label for="proveedor_id"
            ><strong
              >SELECCIONE PROVEEDOR: <span class="p-invalid">*</span></strong
            ></label
          >
          <Dropdown
            v-model="proveedorSelect"
            :options="proveedores"
            autofocus
            optionLabel="nombre_completo"
            :filter="true"
            placeholder="Seleccione un Proveedor"
            @change="buscarLaboratorios"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-5">
          <label for="laboratorios"
            ><strong>MARCAS DEL PROVEEDOR:</strong></label
          ><br />
          <span
            v-for="(laboratorios, index) in laboratoriosSelect"
            :key="index"
          >
            <span v-if="index < 5">
              <span class="p-chip p-mr-1">
                {{ laboratorios.nombre }}
              </span>
            </span>
            <span v-else-if="index === 5">
              {{ laboratoriosSelect.length - 5 }} más...
            </span>
          </span>
        </div>
        <div class="field col-12 md:col-2">
          <label for="laboratorios"><strong>FECHA COMPRA:</strong> </label>
          <Calendar
            v-model="fecha_compra"
            required="true"
            dateFormat="dd/mm/yy"
            :showIcon="true"
            disabled
          />
        </div>
        <!-- <div class="field col-12 md:col-12 hidden">
          <label for="buscar_producto"
            ><strong
              >BUSCAR PRODUCTO | NOMBRE | DESCRIPCI&Oacute;N:</strong
            ></label
          >
          <span
            :class="
              !buscando_producto ? 'p-input-icon-left' : 'p-input-icon-right'
            "
          >
            <i
              :class="
                buscando_producto ? 'pi pi-spin pi-spinner' : 'pi pi-search'
              "
            />
            <div class="p-inputgroup">
              <AutoComplete
                v-model="productoSelect"
                :disabled="buscando_producto"
                field="descripcion"
                placeholder="Buscar Producto"
                :suggestions="productos"
                :delay="500"
                @complete="buscarProductoPorCodigoDescripcion"
                @item-select="agregarProductoDetalle"
                @keyup.enter="buscarProductoPorCodigoDescripcion"
                :dataKey="'id'"
              >
                <template #item="slotProps">
                  <div>
                    {{ slotProps.item.descripcion }} **
                    <strong>{{ slotProps.item.fabrica_nombre }} **</strong>
                    <span
                      :class="
                        stockClass(
                          slotProps.item.stock.length > 0
                            ? sinDecimal(slotProps.item.stock_actual)
                            : 0
                        )
                      "
                      ><strong>{{
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock_actual)
                          : 0
                      }}</strong></span
                    >
                  </div>
                </template>
              </AutoComplete>
              <Button
                icon="pi pi-plus"
                v-if="'Producto Crear' in auth.user.permissions"
                class="p-button"
                v-tooltip.top="'Nuevo Producto'"
                @click="activarProducto"
              />
            </div>
          </span>
        </div> -->
        <div class="field col-12 md:col-5">
          <label for="productos"
            ><strong
              >PRODUCTOS DEL PROVEEDOR: <span class="p-invalid">*</span></strong
            >
            {{ cantidadProductosProveedor }}</label
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="productoSeleccionado"
              :options="grupoProductos"
              placeholder="Seleccione un producto"
              optionLabel="label"
              optionGroupLabel="label"
              optionGroupChildren="items"
              :filter="true"
              :disabled="!laboratoriosSelect.length"
            >
            </Dropdown>
            <Button
              icon="pi pi-plus"
              v-if="'Producto Crear' in auth.user.permissions"
              class="p-button"
              v-tooltip.top="'Nuevo Producto'"
              @click="activarProducto"
            />
            <Button
              icon="pi pi-eye"
              :disabled="grupoProductos.length == 0"
              severity="info"
              text
              raised
              v-tooltip.top="'Ver Listado Productos'"
              @click="activarListadoProductos"
            />
          </div>
          <small
            class="p-invalid text-red-500"
            v-if="errors.productoSeleccionado"
            >{{ errors.productoSeleccionado[0] }}</small
          >
        </div>
        <div class="field col-12 md:col-7">
          <label><strong>PRODUCTOS EN GRAL. :</strong></label>
          <div class="p-inputgroup">
            <AutoComplete
              v-model="productoSeleccionadoTodos"
              :disabled="buscando_producto"
              field="descripcion"
              :delay="500"
              placeholder="Buscar Producto por código o nombre"
              :suggestions="productos_encontrados"
              @complete="buscarProductoPorCodigoDescripcion_todos"
              @item-select="agregarProductoTodosDetalle"
              @keyup.enter="buscarProductoPorCodigoDescripcion_todos"
              :dataKey="'id'"
              :filter="true"
            >
              <!-- @item-select="agregarProductoDetalle"
              @keyup.enter="buscarProductoPorCodigoDescripcion_todos" -->
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }} **</strong>
                  {{ slotProps.item.fabrica_nombre }} **
                  <span
                    :class="
                      stockClass2(
                        slotProps.item.stock.length > 0
                          ? sinDecimal(slotProps.item.stock_actual)
                          : 0
                      )
                    "
                    ><strong>{{
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock_actual)
                        : 0
                    }}</strong></span
                  ><strong v-show="slotProps.item.stock.length > 0">
                    ** PC:
                  </strong>
                  {{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].ultimo_precio_compra
                      : ""
                  }}<strong v-show="slotProps.item.stock.length > 0">
                    ** PV:
                  </strong>
                  {{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].ultimo_precio
                      : ""
                  }}
                  <span v-show="busqueda_caso_uso">
                    ** <strong>P.ACTIVO:</strong>
                    {{ slotProps.item.caso_uso ?? "" }}
                  </span>
                </div>
              </template>
            </AutoComplete>
            <Checkbox
              v-model="busqueda_caso_uso"
              :binary="true"
              class="mt-1 ml-2"
              v-tooltip.top="'Buscar por Principio Activo'"
            /><Badge class="bg-info text-white mt-1 ml-1">P.ACTIVO</Badge>
          </div>
        </div>

        <!-- <div class="field col-12 md:col-4">
          <label for=""
            ><strong
              >Agregar al Detalle: <span class="p-invalid">*</span></strong
            >
          </label>
          <Button
            label="Agregar al Detalle"
            icon="pi pi-shopping-cart"
            @click="agregarProductoDetalle"
            :loading="enviando"
          />
        </div> -->
        <div class="field col-12 md:col-12">
          <label for="detalle"><strong>DETALLE DE COMPRAS:</strong></label>
          <DataTable
            ref="dtnuevasolicitudcompra"
            :value="detalleProductos"
            editMode="cell"
            @cell-edit-complete="onCellEditComplete"
            class="p-datatable-sm"
            responsive="true"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <!-- @cell-edit-init="onCellEditInit" -->
            <Column header="#" headerStyle="width:3rem">
              <template #body="slotProps">
                {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="producto.fabrica_nombre" header="MARCA">
            </Column>
            <!-- <Column field="producto.id" header="CÓD. INTERNO">
              {{ data.producto.id }}
            </Column> -->
            <Column
              field="producto.descripcion"
              header="DESCRIPCIÓN/DETALLE"
              style="font-weight: bold"
            >
              {{ data.producto.descripcion }}
            </Column>
            <Column
              class="text-right"
              field="producto.stock_actual"
              header="STOCK"
            >
              <template #body="{ data }">
                <div :class="stockClass(data)">
                  {{ sinDecimal(data.producto.stock_actual) }}
                </div>
              </template>
            </Column>
            <Column
              field="cantidad"
              header="CANT/UNIDAD"
              class="text-right bg-green-200"
            >
              <template #body="{ data }">
                {{ data.cantidad }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  input-class="text-right"
                  v-model="data[field]"
                  autofocus
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column field="precio" header="PRECIO UNITARIO" class="text-right">
              <template #body="{ data }">
                {{ convertirNumeroGermanicFormat(data.precio) }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  autofocus
                  input-class="text-right"
                  :minFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column field="subtotal" header="SUB TOTAL" class="text-right">
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(
                    slotProps.data.cantidad * slotProps.data.precio
                  )
                }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  autofocus
                  input-class="text-right"
                  :minFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column field="quantity" header="QUITAR">
              <template #body="slotProps">
                <Button
                  @click="quitar(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </template>
            </Column>
            <template #empty>
              <span
                class="flex align-items-center justify-content-center p-invalid"
                >No existen Productos en el Detalle!</span
              >
            </template>

            <template #footer>
              <div class="text-right">
                <h4>
                  TOTAL OC:
                  <strong>{{
                    convertirNumeroGermanicFormat(totalCompra)
                  }}</strong>
                  Bs.
                </h4>
              </div>
            </template>
          </DataTable>
        </div>
        <div class="field col-12 md:col-12">
          <label for="observacion"><strong>OBSERVACIONES: </strong> </label>
          <Textarea
            id="observacion"
            v-model="observacion"
            required="true"
            rows="2"
            placeholder="Ingrese una observación"
            @keyup.enter="guardarCompra"
          >
          </Textarea>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="enviando"
        :loading="enviando"
      />
      <Button
        label="Guardar"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        v-tooltip.top="'Guardar Compra'"
        @click="guardarCompra"
        :disabled="enviando"
        :loading="enviando"
      />
    </template>
  </Dialog>
  <ProveedorCreate
    :show="proveedorModal"
    :proveedor="proveedor"
    @closeModal="cerrarModalProveedor"
    @actualizarListado="cargarProveedores"
  >
  </ProveedorCreate>
  <ProductoCreate
    :mostrar="productoModal"
    :producto="producto"
    @closeModal="cerrarModalProducto"
    @actualizarListadoProductos="buscarLaboratorios"
  >
  </ProductoCreate>
  <ProducoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="modalListadoProductos"
    :compra="true"
    :productos="productos"
    @agregarProductos="agregarProductos"
    :detalleProductos="detalleProductos"
  />
</template>

<script>
import ProveedorCreate from "@/module/proveedores/ProveedorCreate.vue";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";
import ProveedorService from "@/service/ProveedorService";
import OrdenCompraService from "@/service/OrdenCompraService";
import ProductService from "@/service/ProductService";
import ProducoListadoModal from "@/module/productos/ProductoListadoModal.vue";

import { useAuth } from "@/stores";

export default {
  name: "ComprasCreate",
  emits: ["closeModal", "actualizarListadoOC"],
  components: {
    ProveedorCreate,
    ProductoCreate,
    ProducoListadoModal,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productoSeleccionadoTodos: null,
      productos_encontrados: [],
      buscar_producto: null,
      buscando_producto: false,
      productoSelect: [],
      productos: [],
      enviando: false,
      fecha_compra: null,
      activarModal: this.mostrar,
      modalListadoProductos: false,
      proveedorSelect: {},
      proveedores: [],
      laboratoriosSelect: {},
      laboratorios: [],
      grupoProductos: [],
      productoSeleccionado: {},
      precio: null,
      observacion: null,
      detalleProductos: [],
      errors: {},
      proveedorModal: false,
      productoModal: false,
      proveedor: { estado: { label: "Activo", value: 1 } },
      producto: { estado: { label: "Activo", value: 1 } },
      totalCompra: 0,
      cantidadProductosProveedor: 0,
      precio_compra_producto: null,
      stockClass: (data) => {
        if (data.stock_actual <= 0) {
          return "outofstock";
        } else if (data.stock_actual <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      stockClass2: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      busqueda_caso_uso: false,
    };
  },
  proveedorService: null,
  ordenCompraService: null,
  productService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.proveedorService = new ProveedorService();
    this.ordenCompraService = new OrdenCompraService();
    this.productService = new ProductService();
  },
  mounted() {
    this.cargarProveedores();
  },
  methods: {
    cerrarModalProductoResultados() {
      this.modalListadoProductos = false;
    },
    activarListadoProductos() {
      this.modalListadoProductos = true;
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          //verifico si el producto ya existe en el detalle de productos para no agregarlo
          let existe = this.detalleProductos.find(
            (item) => item.producto_id == producto.id
          );
          if (!existe) {
            this.detalleProductos.push({
              ...producto,
              producto: { ...producto },
              cantidad: null,
              producto_id: producto.id,
              /* sucursal_id: producto.stock[0].sucursal_id, */
              nombre_producto: producto.descripcion,
              cantidad_venta: null,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              precio: producto.precio_compra,
              ultimo_precio: parseFloat(producto.precio_sugerido),
              precio_sugerido: parseFloat(producto.precio_sugerido),
              total: parseFloat(producto.precio_sugerido * 1),
            });
          } else {
            this.$toast.add({
              severity: "warn",
              summary: "Advertencia",
              detail: "El producto ya se encuentra en la lista",
              life: 3000,
            });
          }
        } catch (error) {
          console.log(error);
        }
      });
      this.cerrarModalProductoResultados();
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
    buscarProductoPorCodigoDescripcion() {
      //pongo un tiempo de espera para que no se ejecute la busqueda cada vez que se presione una tecla
      /* this.buscando_producto = true; */
      setTimeout(() => {
        this.productService
          .buscar_x_codigo_nombre(this.productoSelect)
          .then((data) => {
            this.productos = data.productos;
            this.buscando_producto = false;
          })
          .catch((error) => {
            this.buscando_producto = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
      }, 200);
    },
    buscarProductoPorCodigoDescripcion_todos() {
      setTimeout(() => {
        /* if (this.productoSeleccionado.length > 4) {
          this.buscando_producto = true; */
        let datos = {
          texto: this.productoSeleccionadoTodos,
          busqueda_caso_uso: this.busqueda_caso_uso,
        };

        this.productService
          .buscar_x_codigo_nombre(datos)
          .then((data) => {
            this.productos_encontrados = data.productos;
            this.buscando_producto = false;
          })
          .catch((error) => {
            this.buscando_producto = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
        /* } */
      }, 250);
    },
    calcularTotal() {
      this.totalCompra = 0;
      this.detalleProductos.forEach((item) => {
        this.totalCompra += item.cantidad * item.precio;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarProductos() {
      this.productService.getAllProductos().then((data) => {
        this.productos = data.productos;
      });
    },
    cargarProveedores() {
      this.proveedorService
        .getProveedores()
        .then((data) => {
          this.proveedores = data.proveedores;
        })
        .catch((error) => console.log(error));
    },
    cerrarModalProveedor() {
      this.proveedorModal = false;
      this.proveedor = {};
      this.cargarProveedores();
    },
    activarProducto() {
      this.productoModal = true;
      this.producto = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerrarModalProducto() {
      this.productoModal = false;
      this.producto = {};
      this.cargarProveedores();
    },
    validarCantidad(data) {
      if (data.cantidad <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "LA CANTIDAD DEBE SER MAYOR A CERO",
          life: 3000,
        });
        data.cantidad = 1;
      }

      /* this.productos.forEach((item) => {
        if (item.id == data.producto.value) {
          if (data.cantidad > item.stock_maximo && item.stock_maximo > 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "La cantidad supera el Stock Máximo de Compra" +
                "Permitido: " +
                item.stock_maximo,
              life: 3000,
            });
            data.cantidad = 1;
          }
        }
      }); */
    },
    onCellEditInit(e) {
      console.log("onCellEditInit", e);
    },
    onCellEditComplete(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (field == "subtotal") {
        data.precio = data.subtotal / data.cantidad;
        this.calcularTotal();
        return;
      }

      this.validarCantidad(data);
      this.calcularTotal();

      //Focus next field
      //let index = this.detalleProductos.indexOf(data);
      //let nextIndex = index + 1;
      //if (nextIndex < this.detalleProductos.length) {
      //  this.$refs.dtnuevasolicitudcompra.onCellEditInit({
      //    data: this.detalleProductos[nextIndex],
      //    field: "cantidad",
      //    index: nextIndex,
      //  });
      //  /* let nextRow = this.detalleProductos[nextIndex]; */
      //}
    },

    validarOrdenCompra() {
      if (this.proveedorSelect.id == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE UN PROVEEDOR",
          life: 6000,
        });
        return false;
      }

      /* if (this.fecha_compra == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "SELECCIONE LA FECHA DE COMPRA",
          life: 6000,
        });
        return false;
      } */

      if (this.detalleProductos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "DEBE SELECCIONAR UN PRODUCTO COMO MINIMO",
          life: 6000,
        });
        return false;
      }

      let producto_cero = false;
      this.detalleProductos.forEach((item) => {
        if (item.cantidad <= 0) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "El producto " + item.producto.descripcion + " tiene cantidad 0",
            life: 6000,
          });
          producto_cero = true;
        }
      });

      if (producto_cero) {
        return false;
      }

      return true;
    },
    guardarCompra() {
      if (this.enviando) {
        return false;
      }
      if (this.validarOrdenCompra()) {
        this.enviando = true;

        let data = {
          proveedor_id: this.proveedorSelect.id,
          productos: this.detalleProductos,
          observacion: this.observacion,
          fecha_compra: this.fecha_compra,
        };

        this.ordenCompraService
          .guardarOC(data)
          .then((response) => {
            if (!response.error) {
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: "Nueva Orden de Compra Solicitada",
                life: 3000,
              });
              this.$emit("actualizarListadoOC");
              this.$emit("closeModal");
              this.closeModal();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.message,
                life: 6000,
              });
            }
            this.enviando = false;
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "Error al guardar la Orden de Compra, Verifique su conexión a internet",
              life: 6000,
            });
            this.enviando = false;
          });
      }
    },
    quitar(producto) {
      this.detalleProductos.splice(producto.index, 1);
      this.calcularTotal();
    },
    buscarLaboratorios() {
      if (this.proveedorSelect.id) {
        if (this.proveedorSelect.id != this.proveedor_id) {
          this.productos = [];
        }
        this.proveedorService
          .obtenerLaboratorios(this.proveedorSelect.id)
          .then((response) => {
            this.grupoProductos = [];
            /* this.detalleProductos = []; */
            this.cantidadProductosProveedor = 0;
            this.laboratorios = this.laboratoriosSelect = response.fabricas;
            try {
              this.laboratorios.forEach((laboratorio) => {
                let items = [];

                laboratorio.productos.forEach((producto) => {
                  this.productos.push(producto);
                  let item = {
                    label: producto.descripcion,
                    value: producto.id,
                  };
                  items.push(item);
                  this.cantidadProductosProveedor++;
                });

                let tmp = {
                  label: laboratorio.nombre,
                  code: laboratorio.id,
                  items: items,
                };
                this.grupoProductos.push(tmp);
              });
            } catch (error) {
              console.log(error);
            }
          })
          .catch((error) => console.log(error));
      }
    },

    closeModal() {
      this.activarModal = false;
      this.proveedorSelect = {};
      this.laboratoriosSelect = {};
      this.$emit("closeModal");
      this.grupoProductos = [];
      this.detalleProductos = [];
      this.observacion = null;
      this.cantidadProductosProveedor = 0;
      this.detalleProductos = [];
    },

    agregarProductoTodosDetalle() {
      this.enviando = true;
      this.errors = {};
      //verifico si el producto ya esta agregado en el detalle de productos para no repetirlo
      let existe = false;
      this.detalleProductos.forEach((item) => {
        if (item.producto.id == this.productoSeleccionadoTodos.id) {
          existe = true;
        }
      });
      if (existe) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra agregado",
          life: 6000,
        });
        this.enviando = false;
        return false;
      }
      // obtengo el precio anterior del producto seleccionado
      let datos = {
        producto_id: this.productoSeleccionadoTodos.id,
      };
      this.ordenCompraService
        .getprecio_compra_producto(datos)
        .then((response) => {
          let tmp_producto = null;

          for (
            let index = 0;
            index < this.productos_encontrados.length;
            index++
          ) {
            if (
              this.productos_encontrados[index].id ==
              this.productoSeleccionadoTodos.id
            ) {
              tmp_producto = this.productos_encontrados[index];
            }
          }

          let item = {
            ...tmp_producto,
            producto: tmp_producto,
            cantidad: null,
            precio: parseFloat(response.stock.precio_compra || 0),
            subtotal: 0,
          };
          this.detalleProductos.push(item);
          this.productoSelect = null;
          this.productoSeleccionadoTodos = null;
          this.calcularTotal();
        });
      this.enviando = false;
    },

    agregarProductoDetalle() {
      this.enviando = true;
      this.errors = {};
      //verifico si el producto ya esta agregado en el detalle de productos para no repetirlo
      let existe = false;
      this.detalleProductos.forEach((item) => {
        if (item.producto.id == this.productoSeleccionado.value) {
          existe = true;
        }
      });
      if (existe) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya se encuentra agregado",
          life: 6000,
        });
        this.enviando = false;
        return false;
      }
      // obtengo el precio anterior del producto seleccionado
      let datos = {
        producto_id: this.productoSeleccionado.value,
      };
      this.ordenCompraService
        .getprecio_compra_producto(datos)
        .then((response) => {
          let tmp_producto = null;

          for (let index = 0; index < this.productos.length; index++) {
            if (this.productos[index].id == this.productoSeleccionado.value) {
              tmp_producto = this.productos[index];
            }
          }

          let item = {
            ...tmp_producto,
            producto: tmp_producto,
            cantidad: null,
            precio: parseFloat(response.stock.precio_compra || 0),
            subtotal: 0,
          };
          this.detalleProductos.push(item);
          this.productoSelect = {};
          this.calcularTotal();
        });
      this.enviando = false;
    },
  },
  watch: {
    mostrar(newVal) {
      this.fecha_compra = new Date();
      this.activarModal = newVal;
      this.calcularTotal();
    },
    productoSeleccionado() {
      if (this.productoSeleccionado !== {}) {
        this.agregarProductoDetalle();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
