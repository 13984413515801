<template>
  <Dialog
    v-model:visible="mostrarModal"
    :modal="true"
    :style="{ width: '1200px' }"
    header="Productos encontrados"
    class="p-fluid"
    @hide="cancelar"
  >
    <div class="col-12">
      <div class="">
        <DataTable
          :value="data_productos"
          class="p-datatable-small"
          v-model:expandedRows="expandedRows"
          stripedRows
          showGridlines
          selectionMode="single click"
          :filters="buscar"
          @row-dblclick="productos_selected.push($event.data)"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar Productos..."
                />
              </span>
            </div>
          </template>
          <Column style="width: 5rem">
            <template #header>
              <i class="pi pi-check-circle ml-4"></i>
            </template>
            <template #body="slotProps">
              <Checkbox
                class="ml-3"
                v-model="productos_selected"
                :value="slotProps.data"
              />
            </template>
          </Column>
          <Column
            field="descripcion"
            header="DESCRIPCIÓN/PRODUCTO"
            :sortable="true"
            style="font-weight: bold; width: 20rem"
          />
          <Column
            header="STOCK"
            class="text-right"
            :sortable="true"
            style="font-weight: bold; width: 5rem"
          >
            <template #body="slotProps">
              <span
                v-if="
                  slotProps.data.stock_actual == null ||
                  slotProps.data.stock_actual == 0
                "
              >
                <span
                  class="text-red-500"
                  style="font-weight: bold; font-size: 1.2rem"
                >
                  {{ slotProps.data.stock_actual ?? 0 }}
                </span>
              </span>
              <span v-else>
                {{
                  slotProps.data.stock[0].cantidad == undefined
                    ? 0
                    : slotProps.data.stock[0].cantidad
                }}
              </span>
            </template>
            <!-- <template #body="slotProps">
              <span>{{
                slotProps.data.stock[0].cantidad == undefined
                  ? 0
                  : slotProps.data.stock[0].cantidad
              }}</span>
            </template> -->
          </Column>
          <Column
            field="precio_sugerido"
            class="text-2xl bg-blue-100 text-right"
            header="PRECIO"
            :sortable="true"
          >
            <template #body="slotProps">
              <span v-if="esCompra">{{ slotProps.data.precio_compra }}</span>
              <span v-else>{{ slotProps.data.precio_sugerido }}</span>
            </template>
          </Column>
          <Column expander header="CASO USO" />
          <template #expansion="slotProps">
            <div class="p-3 text-right">
              <p for="caso_uso"><strong>PRINCIPIO ACTIVO : </strong></p>
              {{ slotProps.data.caso_uso }}
            </div>
          </template>
          <!-- <Column field="codigo_barra" header="Código Barra" :sortable="true" /> -->
          <Column
            field="unidad_medida_nombre"
            header="U. MEDIDA"
            :sortable="true"
          />
          <Column field="grupo_nombre" header="GRUPO" :sortable="true" />
          <Column field="fabrica_nombre" header="MARCA" :sortable="true" />
          <Column field="ubicacion" header="UBICACIÓN">
            <template #body="slotProps">
              <span v-if="slotProps.data.stock.length > 0">
                {{ slotProps.data.stock[0].ubicacion }}
              </span>
              <span v-else>{{ slotProps.data.ubicacion }}</span>
            </template>
          </Column>
          <Column
            field="imagen"
            header="IMAGEN"
            v-if="'Producto VerImagen' in auth.user.permissions"
          >
            <template #body="{ data }">
              <template v-if="data.imagen != null">
                <Image
                  :src="'data:image/png;base64, ' + data.imagen"
                  width="50"
                  preview
                ></Image>
              </template>
              <template v-else><p>---</p></template>
            </template>
          </Column>
          <!--  <Column header="Sucursal" :sortable="true">
            <template #body="slotProps">
              <span>{{ slotProps.data.stock[0].nombre_sucursal }}</span>
            </template>
          </Column> -->
        </DataTable>
      </div>
    </div>
    <template #footer>
      <div>
        <Button
          label="CANCELAR"
          icon="pi pi-times"
          class="p-button-danger p-button-lg"
          @click="cancelar"
        />
        <Button
          label="AÑADIR PRODUCTOS"
          icon="pi pi-shopping-cart"
          class="p-button-primary p-button-lg"
          @click="agregarProductos"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";

export default {
  emits: ["closeModal", "agregarProductos"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productos: {
      type: Array,
      default() {
        return [];
      },
    },
    compra: {
      type: Boolean,
      default: false,
    },
    detalleProductos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  auth: null,
  created() {
    this.auth = useAuth();
  },
  data() {
    return {
      mostrarModal: this.show,
      data_productos: this.productos,
      productos_selected: [],
      expandedRows: [],
      esCompra: this.compra,
      buscar: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },

  methods: {
    cancelar() {
      this.$emit("closeModal");
    },
    marcarTodos() {
      this.productos_selected = this.data_productos.map((item) => item.id);
    },
    agregarProductos() {
      this.$emit("agregarProductos", this.productos_selected);
    },
    /*  buscar_producto_del_detalle(){
      console.log('detalleProductos',this.detalleProductos);
      this.detalleProductos.forEach((item) => {
        //Busco el producto en el listado
        console.log('item',item);
        let producto = this.data_productos.find(
          (prod) => prod.id == item.id
        );
        //Ingresar a productos seleccionados
        this.productos_selected.push(producto);
      });
    } */
  },

  watch: {
    show(value) {
      this.mostrarModal = value;
    },
    productos(value) {
      this.data_productos = value;
      /*  this.buscar_producto_del_detalle(); */
    },
  },
};
</script>
